<template>
    <div id="order-services">
    <HeadOrders
    :TitlePage="$route.name"
    :PathPageFrom="$t('Home')"
    :PathPageCenter="$t('Services')"
    PathPageTo="الخدمة"
    pathFrom="/"
    pathCenter="/services"
    />
    <div class="container">
    <Order />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Service Order',
  components: {
    HeadOrders: defineAsyncComponent( () => import('@/components/Global/HeadOrders.vue') ),
    Order: defineAsyncComponent( () => import('@/components/Services/Order/Order.vue') ),
  },
  }
  </script>
<style scoped>
  @media (min-width: 800px) and (max-width: 992px) {
    .container {
      max-width: 752px;
    }
  }
</style>
